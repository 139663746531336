import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { DISCOUNT_SET_PRICE_RULE, DISCOUNT_SETS } from '../query-keys';
import type { Get, Patch } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type DiscountSet = {
	id: number;
	title: string;
	totalCodes: number;
	totalImportedCodes: number;
	interruptImport: boolean;
	generatorDefinition: GeneratorDefinition;
	discountSetType: DiscountSetType;
	createdAt: Date;
	updatedAt: Date;
	queuedAt?: Date;
};

export type GeneratorDefinitionType = 'alphabetical' | 'numeric' | 'alphanumeric';
export type DiscountSetType = 'generated' | 'manual';

export type GeneratorDefinition = {
	prefix?: string;
	suffix?: string;
	totalCharacters: number;
	type: GeneratorDefinitionType;
	separated: boolean;
	separator: string;
	separatorSpacing: number;
};

export type PriceRule = {
	targetType: 'line_item' | 'shipping_line';
	targetSelection: 'all' | 'entitled';
	allocationMethod: 'across' | 'each';
	valueType: 'fixed_amount' | 'percentage';
	value: string;
	allocationLimit?: number;
	oncePerCustomer: boolean;
	usageLimit?: number;
	customerSelection: 'all' | 'prerequisite';
	prerequisiteSubtotalRange?: {
		greaterThanOrEqualTo: string;
	};
	prerequisiteShippingPriceRange?: {
		lessThanOrEqualTo: string;
	};
	prerequisiteQuantityRange?: {
		greaterThanOrEqualTo: string;
	};
	prerequisiteCustomerIds?: number[];
	prerequisiteCollectionIds?: number[];
	prerequisiteProductIds?: number[];
	prerequisiteVariantIds?: number[];
	customerSegmentPrerequisiteIds?: string[];
	entitledProductIds?: number[];
	entitledVariantIds?: number[];
	entitledCollectionIds?: number[];
	entitledCountryIds?: number[];
	prerequisiteToEntitlementQuantityRatio?: {
		prerequisiteQuantity: number;
		entitledQuantity: number;
	};
	prerequisiteToEntitlementPurchase?: {
		prerequisiteAmount: string;
	};

	startsAt: Date;
	endsAt?: Date;
	createdAt: Date;
	updatedAt: Date;
};

export function useDiscountSets(accountId: number, limit = 10) {
	const { get } = useAuthenticatedFetch() as Get<DiscountSet[]>;
	const {
		data: discountSets,
		refetch: loadDiscountSets,
		isFetching: isLoading
	} = useQuery({
		queryKey: queryKey(DISCOUNT_SETS, accountId, limit),
		queryFn: () => get(`/bulk-accounts/${accountId}/discount-sets?limit=${limit}`),
		select: useDateTransformerFactory('queuedAt')
	});

	return { discountSets: discountSets ?? [], loadDiscountSets, isLoading };
}

export function useDiscountSetPriceRule(accountId: number, discountSetId: number) {
	const { get } = useAuthenticatedFetch() as Get<PriceRule>;
	const { data: priceRule, isFetching: isLoading } = useQuery({
		queryKey: queryKey(DISCOUNT_SET_PRICE_RULE, accountId, discountSetId),
		queryFn: () => get(`/bulk-accounts/${accountId}/discount-sets/${discountSetId}/price-rule`),
		select: useDateTransformerFactory('startsAt', 'endsAt')
	});

	return { priceRule, isLoading };
}

export function useExportDiscountSets(accountId: number) {
	const { get } = useAuthenticatedFetch() as Get<void>;
	const { mutate: exportDiscountSet, isPending: isExporting } = useMutation({
		mutationFn: ({ id, title }: { id: number; title: string }) =>
			get(
				`/bulk-accounts/${accountId}/discount-sets/${id}/export`,
				{
					download: true,
					filename: `${title}-${format(new Date(), 'yyyy-MM-dd-HHmm')}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	});
	return { exportDiscountSet, isExporting };
}

export function useTerminateDiscountImport(accountId: number) {
	const { patch } = useAuthenticatedFetch() as Patch<string, void>;
	const queryClient = useQueryClient();
	const { mutate: terminateDiscountImport } = useMutation({
		mutationFn: ({ id, reason }: { id: number; reason: string }) =>
			patch(`/bulk-accounts/${accountId}/discount-sets/${id}/terminate-import`, reason),

		onSuccess: () => {
			queryClient.removeQueries({ queryKey: queryKey(DISCOUNT_SETS, accountId) });
		}
	});

	return { terminateDiscountImport };
}
