import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
	MARKETING_PLATFORM_ACCOUNTS_DRAFT_NEWSLETTERS,
	MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTER_CUSTOMER_GROUPS,
	MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTERS,
	MARKETING_PLATFORM_ACCOUNTS_SCHEDULED_NEWSLETTERS
} from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

const LIMIT = 10;

export type CampaignStatus = 'CREATED' | 'PAUSED' | 'SCHEDULED' | 'PROCESSING' | 'COMPLETED';
export type TargetRecipients =
	| 'ALL'
	| 'CUSTOMER_SAVED_SEARCHES'
	| 'INHERITED_DELIVERIES'
	| 'INHERITED_OPENS'
	| 'INHERITED_CLICKS'
	| 'INHERITED_NON_OPENS'
	| 'INHERITED_NON_CLICKS';
export type DiscountCodeType = 'NONE' | 'SINGLE_CODE' | 'GENERATED' | 'INHERITED';

export type ConversionSummary = {
	conversions: number;
	totalRevenue: number;
	totalProductsPurchased: number;
	totalFirstPurchasers: number;
};

export type Newsletter = {
	id: number;
	publicId: string;
	name: string;
	campaignStatus: CampaignStatus;
	scheduledAt?: Date;
	sentAt?: Date;
	expectedCompletionAt?: Date;
	targetRecipients: TargetRecipients;
	sends: number;
	deliveries: number;
	opens: number;
	clicks: number;
	bounces: number;
	spamComplaints: number;
	unsubscribes: number;
	discountCodeType: DiscountCodeType;
	discountCode?: string;
	conversionSummary: ConversionSummary;
	createdAt: Date;
	updatedAt: Date;
};

export type Segment = {
	name: string;
	exclusion: boolean;
	query: string;
};

export function useRecentNewsletters(accountId: number) {
	const { get } = useAuthenticatedFetch() as Get<Newsletter[]>;

	const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTERS, accountId),
		queryFn: ({ pageParam = 0 }) => get(`/marketing-platform-accounts/${accountId}/recent-newsletters?page=${pageParam}`),
		enabled: !!accountId,
		initialPageParam: 0,
		getNextPageParam: (lastPage, pages) => (lastPage.length === LIMIT ? pages.length : undefined),
		select: useDateTransformerFactory('scheduledAt', 'sentAt', 'expectedCompletionAt')
	});

	return {
		recentNewsletters: data?.pages ?? [],
		refetch,
		isLoading: isFetching,
		hasNextPage,
		fetchNextPage
	};
}

export function useScheduledNewsletters(accountId: number) {
	const { get } = useAuthenticatedFetch() as Get<Newsletter[]>;

	const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNTS_SCHEDULED_NEWSLETTERS, accountId),
		queryFn: ({ pageParam = 0 }) => get(`/marketing-platform-accounts/${accountId}/scheduled-newsletters?page=${pageParam}`),
		initialPageParam: 0,
		enabled: !!accountId,
		getNextPageParam: (lastPage, pages) => (lastPage.length === LIMIT ? pages.length : undefined),
		select: useDateTransformerFactory('scheduledAt', 'sentAt', 'expectedCompletionAt')
	});

	return {
		scheduledNewsletters: data?.pages ?? [],
		refetch,
		isLoading: isFetching,
		hasNextPage,
		fetchNextPage
	};
}

export function useDraftNewsletters(accountId: number) {
	const { get } = useAuthenticatedFetch() as Get<Newsletter[]>;

	const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNTS_DRAFT_NEWSLETTERS, accountId),
		queryFn: ({ pageParam = 0 }) => get(`/marketing-platform-accounts/${accountId}/draft-newsletters?page=${pageParam}`),
		initialPageParam: 0,
		enabled: !!accountId,
		getNextPageParam: (lastPage, pages) => (lastPage.length === LIMIT ? pages.length : undefined),
		select: useDateTransformerFactory('scheduledAt', 'sentAt', 'expectedCompletionAt')
	});

	return {
		draftNewsletters: data?.pages ?? [],
		refetch,
		isLoading: isFetching,
		hasNextPage,
		fetchNextPage
	};
}

export function useRecentNewsletterCustomerGroups(accountId: number, newsletterId?: number) {
	const { get } = useAuthenticatedFetch() as Get<Segment[]>;
	const { data, isFetching } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTER_CUSTOMER_GROUPS, accountId, newsletterId),
		queryFn: () => get(`/marketing-platform-accounts/${accountId}/${newsletterId}/customer-groups`),
		enabled: Boolean(accountId && newsletterId)
	});

	return {
		customerGroups: data ?? [],
		isLoadingCustomerGroups: isFetching
	};
}
