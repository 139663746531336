import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK } from '../query-keys';
import type { Get, Post } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import {
	useMarketingPlatformAccount,
	useUpdateAccount,
	type BillingPlan,
	type MarketingPlatformAccount
} from './useMarketingPlatformAccount';

export type ExportActivity = {
	eventId: number;
	isCampaign: boolean;
	name: string;
};

type CancelNewsletter = { campaignId: number };

type ExportSendStats = { limit: number };

function getActionPath(id: string | number, path: string) {
	return `/marketing-platform-accounts/${id}${path}`;
}

export function useMarketingPlatformAccountActions(id: string | number) {
	const { post, get } = useAuthenticatedFetch() as Post<any, MarketingPlatformAccount> & Get<MarketingPlatformAccount>;
	const { account } = useMarketingPlatformAccount(id);
	const queryClient = useQueryClient();
	const updateAccount = useUpdateAccount(id);

	const { mutate: setSuspended, isPending: isSettingSuspended } = useMutation({
		mutationFn: suspended =>
			post(getActionPath(id, '/set-suspended'), {
				suspended
			}).then(updateAccount)
	});

	const { mutate: setSuspicious, isPending: isSettingSuspicious } = useMutation({
		mutationFn: suspicious =>
			post(getActionPath(id, '/set-suspicious'), {
				suspicious
			}).then(updateAccount)
	});

	const { mutate: setTrusted, isPending: isSettingTrusted } = useMutation({
		mutationFn: trusted =>
			post(getActionPath(id, '/set-trusted'), {
				trusted
			}).then(updateAccount)
	});

	const { mutate: setTrustedImporter, isPending: isSettingTrustedImporter } = useMutation({
		mutationFn: trustedImporter =>
			post(getActionPath(id, '/set-trusted-importer'), {
				trustedImporter
			}).then(updateAccount)
	});

	const { mutate: setListCleaningLevel, isPending: isSettingListCleaningLevel } = useMutation({
		mutationFn: listCleaningLevel =>
			post(getActionPath(id, '/set-list-cleaning-level'), {
				listCleaningLevel
			}).then(updateAccount)
	});

	const { mutate: setSuppressionTags, isPending: isSettingSuppressionTags } = useMutation({
		mutationFn: suppressionTags =>
			post(getActionPath(id, '/set-suppression-tags'), {
				suppressionTags
			}).then(updateAccount)
	});

	const { mutate: refreshBilling, isPending: isRefreshingBilling } = useMutation({
		mutationFn: () => post(getActionPath(id, '/update-billing'), undefined).then(updateAccount)
	});

	const { mutate: updateTrial, isPending: isUpdatingTrial } = useMutation({
		mutationFn: newTrialDate => post(getActionPath(id, '/update-trial'), newTrialDate).then(updateAccount)
	});

	const { mutate: verifyDomain, isPending: isVerifyingDomain } = useMutation({
		mutationFn: () => post(getActionPath(id, '/verify-domain'), undefined).then(updateAccount)
	});

	const { mutate: resetWarming, isPending: isResettingWarming } = useMutation({
		mutationFn: () => post(getActionPath(id, '/reset-warming'), undefined).then(updateAccount)
	});

	const { mutate: setSendingDomain, isPending: isSettingSendingDomain } = useMutation({
		mutationFn: domain =>
			post(getActionPath(id, '/set-sending-domain'), {
				domain
			}).then(updateAccount)
	});

	const { mutate: setComplianceHold, isPending: isSettingComplianceHold } = useMutation({
		mutationFn: complianceHold =>
			post(getActionPath(id, '/set-compliance-hold'), {
				complianceHold
			}).then(updateAccount)
	});

	const { mutate: setCsmNote, isPending: isSettingCsmNote } = useMutation({
		mutationFn: note =>
			post(getActionPath(id, '/set-csm-note'), {
				note
			}).then(updateAccount)
	});

	const { mutate: setResourceFeedback, isPending: isSettingResourceFeedback } = useMutation({
		mutationFn: message =>
			post(getActionPath(id, '/set-resource-feedback'), {
				message
			}).then(() => queryClient.removeQueries({ queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK) }))
	});

	const { mutate: setAllowSendingOverride, isPending: isSettingAllowSendingOverride } = useMutation({
		mutationFn: allowSendingOverride =>
			post(getActionPath(id, '/set-allow-sending-override'), {
				allowSendingOverride
			}).then(updateAccount)
	});

	const { mutate: setAllowSendingWhileWarming, isPending: isSettingAllowSendingWhileWarming } = useMutation({
		mutationFn: allowSendingWhileWarming =>
			post(getActionPath(id, '/set-allow-sending-while-warming'), {
				allowSendingWhileWarming
			}).then(updateAccount)
	});

	const { mutate: setIpPool, isPending: isSettingIpPool } = useMutation({
		mutationFn: ipPoolId => post(getActionPath(id, `/set-ip-pool/${ipPoolId}`), undefined).then(updateAccount)
	});

	const { mutate: setUnsubscribeText, isPending: isSettingUnsubscribeText } = useMutation({
		mutationFn: ({ language, unsubscribeText }: { language: string; unsubscribeText: string }) =>
			post(getActionPath(id, '/set-unsubscribe'), {
				language,
				unsubscribeText
			}).then(updateAccount)
	});

	const { mutate: setContact, isPending: isSettingContact } = useMutation({
		mutationFn: ({ contactName, contactEmail }: { contactName: string; contactEmail: string }) =>
			post(getActionPath(id, '/set-contact'), { contactName, contactEmail }).then(updateAccount)
	});

	const { mutate: confirmDowngrade, isPending: isConfirmingDowngrade } = useMutation({
		mutationFn: () => post(getActionPath(id, '/confirm-downgrade'), undefined).then(updateAccount)
	});

	const { mutate: cancelCharge, isPending: isCancellingCharge } = useMutation({
		mutationFn: () => post(getActionPath(id, '/cancel-charge'), undefined).then(updateAccount)
	});

	const { mutate: setScreenRecord, isPending: isSettingScreenRecord } = useMutation({
		mutationFn: screenRecord => post(getActionPath(id, `/set-screen-record/${screenRecord}`), undefined).then(updateAccount)
	});

	const { adjustBillingDetails, isAdjustingBillingDetails } = useSetBillingDetails(id);

	const { adjustStoreCredits, isAdjustingStoreCredits } = useAdjustCredits(id);

	const { mutate: exportCustomers, isPending: isExportingCustomers } = useMutation({
		mutationFn: ({
			exportType,
			includeTags,
			excludeSuppressions
		}: {
			exportType: string;
			includeTags: boolean;
			excludeSuppressions: boolean;
		}) =>
			get(
				getActionPath(id, `/export/${exportType}?includeTags=${includeTags}&excludeSuppressions=${excludeSuppressions}`),
				{
					download: true,
					filename: `${id}_${account?.name}_${exportType}-${format(new Date(), 'yyyy-MM-dd-HHmm')}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	});

	const { mutate: exportEmailActivity, isPending: isExportingEmailActivity } = useMutation({
		mutationFn: ({ eventId, isCampaign, name }: ExportActivity) =>
			get(
				getActionPath(id, `/export/${eventId}/email-activity?isCampaign=${isCampaign}`),
				{
					download: true,
					filename: `${id}_${account?.name}_${isCampaign ? 'newsletter' : 'automation'}_${name}_EXPORT-${format(
						new Date(),
						'yyyy-MM-dd-HHmm'
					)}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	});

	const { mutate: exportNewsletterSendStats, isPending: isExportingNewsletterSendStats } = useMutation({
		mutationFn: ({ limit }: ExportSendStats) =>
			get(
				getActionPath(id, `/stats/newsletters?limit=${limit}`),
				{
					download: true,
					filename: `${id}_${account?.name}_newsletters_EXPORT-${format(new Date(), 'yyyy-MM-dd-HHmm')}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	});

	const {
		mutate: exportAnalysis,
		isPending: isExportingAnalysis,
		error: analysisExportError
	} = useMutation({
		mutationFn: () =>
			get(
				getActionPath(id, `/export/analysis`),
				{
					download: true,
					filename: `${id}_${account?.name}_ANALYSIS-${format(new Date(), 'yyyy-MM-dd-HHmm')}.pdf`
				},
				{
					Accept: 'application/pdf'
				}
			)
	});

	const {
		mutate: cancelNewsletter,
		isPending: isCancellingNewsletter,
		error: cancelNewsletterError,
		reset: resetCancelNewsletter
	} = useMutation({
		mutationFn: ({ campaignId }: CancelNewsletter) => post(getActionPath(id, `/campaigns/${campaignId}/cancel`), undefined)
	});

	const {
		mutate: removeFromSpamProtect,
		isPending: isRemovingFromSpamProtect,
		isSuccess: removeFromSpamProtectSuccess,
		error: removeFromSpamProtectError,
		reset: resetRemoveFromSpamProtect
	} = useMutation({
		mutationFn: (email: string) =>
			post(getActionPath(id, '/set-global-suppression-override'), {
				email,
				globalSuppressionOverride: true
			})
	});

	return {
		setSuspended,
		isSettingSuspended,
		setSuspicious,
		isSettingSuspicious,
		setTrusted,
		isSettingTrusted,
		setTrustedImporter,
		isSettingTrustedImporter,
		setListCleaningLevel,
		isSettingListCleaningLevel,
		setSuppressionTags,
		isSettingSuppressionTags,
		verifyDomain,
		isVerifyingDomain,
		resetWarming,
		isResettingWarming,
		setSendingDomain,
		isSettingSendingDomain,
		setComplianceHold,
		isSettingComplianceHold,
		setAllowSendingOverride,
		isSettingAllowSendingOverride,
		setAllowSendingWhileWarming,
		isSettingAllowSendingWhileWarming,
		setIpPool,
		isSettingIpPool,
		setCsmNote,
		isSettingCsmNote,
		setUnsubscribeText,
		isSettingUnsubscribeText,
		setContact,
		isSettingContact,
		confirmDowngrade,
		isConfirmingDowngrade,
		cancelCharge,
		isCancellingCharge,
		setScreenRecord,
		isSettingScreenRecord,
		adjustBillingDetails,
		isAdjustingBillingDetails,
		adjustStoreCredits,
		isAdjustingStoreCredits,
		exportCustomers,
		isExportingCustomers,
		exportEmailActivity,
		isExportingEmailActivity,
		refreshBilling,
		isRefreshingBilling,
		updateTrial,
		isUpdatingTrial,
		exportNewsletterSendStats,
		isExportingNewsletterSendStats,
		exportAnalysis,
		isExportingAnalysis,
		analysisExportError,
		setResourceFeedback,
		isSettingResourceFeedback,
		cancelNewsletter,
		isCancellingNewsletter,
		cancelNewsletterError,
		resetCancelNewsletter,
		removeFromSpamProtect,
		isRemovingFromSpamProtect,
		removeFromSpamProtectSuccess,
		removeFromSpamProtectError,
		resetRemoveFromSpamProtect
	};
}

export type AdjustCreditsRequest = {
	amount: number;
};

function useAdjustCredits(id: string | number) {
	const { post } = useAuthenticatedFetch() as Post<AdjustCreditsRequest, MarketingPlatformAccount>;
	const updateAccount = useUpdateAccount(id);
	const { mutate: adjustStoreCredits, isPending: isAdjustingStoreCredits } = useMutation({
		mutationFn: (amount: number) =>
			post(getActionPath(id, '/adjust-store-credits'), {
				amount
			}).then(updateAccount)
	});
	return { adjustStoreCredits, isAdjustingStoreCredits };
}

export type BillingDetails = {
	billingPlan: BillingPlan;
	trialDays: number;
	freeTierCap: number;
	basePrice: number;
	includedBillingUnits: number;
	billingUnit: number;
	pricePerUnit: number;
};

export type AdjustBillingDetailsParameters = {
	billingDetails: BillingDetails;
	gracePeriodDate?: Date;
};

type AdjustBillingDetailsMutation = {
	billingDetails: BillingDetails;
	gracePeriodInMillis?: number;
};

function useSetBillingDetails(id: string | number) {
	const { post } = useAuthenticatedFetch() as Post<AdjustBillingDetailsMutation, MarketingPlatformAccount>;
	const updateAccount = useUpdateAccount(id);
	const { mutate: adjustBillingDetails, isPending: isAdjustingBillingDetails } = useMutation({
		mutationFn: ({ billingDetails, gracePeriodDate }: AdjustBillingDetailsParameters) =>
			post(getActionPath(id, '/set-billing-details'), { billingDetails, gracePeriodInMillis: gracePeriodDate?.getTime() }).then(
				updateAccount
			)
	});
	return { adjustBillingDetails, isAdjustingBillingDetails };
}
