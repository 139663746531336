import {
	Card,
	FormatNumber,
	FormatPercent,
	getCurrencyCode,
	Heading,
	NumberedLabel,
	Stack,
	TextStyle,
	useHasChangedToFalsy
} from '@segunosoftware/equinox';
import { Badge, Button, DataTable, Form, FormLayout, Modal, Pagination, RangeSlider, Tooltip } from '@shopify/polaris';
import { ChartVerticalIcon, ExportIcon } from '@shopify/polaris-icons';
import { format } from 'date-fns';
import type { StringifiableRecord } from 'query-string';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMarketingPlatformAccount, type MarketingPlatformAccount } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { useMarketingPlatformAccountActions, type ExportActivity } from '../../hooks/marketingplatform/useMarketingPlatformAccountActions';
import { useRecentNewsletters, type Newsletter } from '../../hooks/marketingplatform/useNewsletters';
import { useUser } from '../../hooks/useUser';
import { isLeadOrAdmin } from '../AccountView';
import ConversionFormatMoney from '../ConversionFormatMoney';
import SlimPolarisTableButtons from '../SlimPolarisTableButtons';
import { truncateText } from './Automations';
import NewsletterPreviewAction from './NewsletterPreviewAction';
import TargetRecipientsDescription from './TargetRecipientsDescription';

const SPARKPOST_ACTIVITY_URL = 'https://app.sparkpost.com/signals/analytics';
const SPARKPOST_ACTIVITY_PARAMS = {
	range: '90days',
	timezone: 'Etc/UTC',
	precision: 'week',
	includePrefetched: true,
	group_by: 'watched-domain'
};
const SPARKPOST_METRICS = [
	'count_targeted',
	'count_injected',
	'count_sent',
	'count_accepted',
	'count_spam_complaint',
	'spam_complaint_rate',
	'open_rate',
	'click_through_rate',
	'bounce_rate',
	'hard_bounce_rate',
	'soft_bounce_rate',
	'block_bounce_rate',
	'unsubscribe_rate',
	'count_delayed',
	'count_outofband_bounce',
	'count_unique_confirmed_opened'
];

const DEFAULT_NEWSLETTER_EXPORT_LIMIT = 20;

export function getSparkPostActivityUrl(account: MarketingPlatformAccount, activity: Newsletter) {
	const params: StringifiableRecord = {
		...SPARKPOST_ACTIVITY_PARAMS,
		query_filters: `[{"AND":{"campaigns":{"eq":["${activity.publicId}"]},"subaccounts":{"eq":["${account.espAccountId}"]}}}]`
	};
	SPARKPOST_METRICS.forEach((metric, index) => (params[`metrics[${index}]`] = metric));
	const url = queryString.stringifyUrl({
		url: SPARKPOST_ACTIVITY_URL,
		query: params
	});
	return url;
}

export type RecentNewslettersProps = {
	accountId: number;
};

export default function RecentNewsletters({ accountId }: RecentNewslettersProps) {
	const user = useUser();
	const { recentNewsletters, refetch, isLoading, hasNextPage, fetchNextPage } = useRecentNewsletters(accountId);
	const [activityToExport, setActivityToExport] = useState<ExportActivity>();
	const { account } = useMarketingPlatformAccount(accountId);
	const {
		exportEmailActivity,
		isExportingEmailActivity,
		exportNewsletterSendStats,
		isExportingNewsletterSendStats,
		cancelNewsletter,
		isCancellingNewsletter,
		cancelNewsletterError,
		resetCancelNewsletter
	} = useMarketingPlatformAccountActions(accountId);
	const [selectedNewsletter, setSelectedNewsletter] = useState<Newsletter>();
	const [showCancelNewsletterModal, setShowCancelNewsletterModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const currentNewsletters = recentNewsletters[currentPage] ?? [];
	const [newsletterStatExportLimit, setNewsletterStatExportLimit] = useState(DEFAULT_NEWSLETTER_EXPORT_LIMIT);
	const [showExportNewsletterStatsModal, setShowExportNewsletterStatsModal] = useState(false);
	const hasExportedNewsletterSendStats = useHasChangedToFalsy(isExportingNewsletterSendStats);
	const hasCancelledNewsletter = useHasChangedToFalsy(isCancellingNewsletter);

	const onHideCancelNewsletterModal = useCallback(() => {
		setSelectedNewsletter(undefined);
		resetCancelNewsletter();
		setShowCancelNewsletterModal(false);
	}, [resetCancelNewsletter]);

	useEffect(() => {
		if (activityToExport) {
			exportEmailActivity(activityToExport);
		}
	}, [activityToExport, exportEmailActivity]);

	useEffect(() => {
		if (!isExportingEmailActivity) {
			setActivityToExport(undefined);
		}
	}, [isExportingEmailActivity]);

	useEffect(() => {
		if (hasCancelledNewsletter && !cancelNewsletterError) {
			onHideCancelNewsletterModal();
			refetch();
		}
	}, [hasCancelledNewsletter, cancelNewsletterError, onHideCancelNewsletterModal, refetch]);

	useEffect(() => {
		if (hasExportedNewsletterSendStats) {
			onHideExportNewsletterStatsModal();
		}
	}, [hasExportedNewsletterSendStats]);

	function onShowCancelNewsletterModal(newsletter: Newsletter) {
		setSelectedNewsletter(newsletter);
		setShowCancelNewsletterModal(true);
	}

	function onHideExportNewsletterStatsModal() {
		setShowExportNewsletterStatsModal(false);
	}

	function onExportNewsletterSendStats() {
		exportNewsletterSendStats({ limit: newsletterStatExportLimit });
	}

	function onCancelNewsletter() {
		if (selectedNewsletter) {
			cancelNewsletter({ campaignId: selectedNewsletter.id });
		}
	}

	function renderStat(stat: number, sends: number) {
		const value = sends === 0 ? 0 : (stat / sends) * 100.0;
		return (
			<span>
				<FormatNumber value={stat} /> (<FormatPercent value={value} decimals={2} />)
			</span>
		);
	}

	function getTotals() {
		let totalSends = 0,
			totalDeliveries = 0,
			totalOpens = 0,
			totalClicks = 0,
			totalBounces = 0,
			totalSpam = 0,
			totalUnsubscribes = 0;
		for (const newsletter of currentNewsletters) {
			const { sends, deliveries, opens, clicks, bounces, spamComplaints, unsubscribes } = newsletter;
			totalSends += sends;
			totalDeliveries += deliveries;
			totalOpens += opens;
			totalClicks += clicks;
			totalBounces += bounces;
			totalSpam += spamComplaints;
			totalUnsubscribes += unsubscribes;
		}
		return [
			'',
			'',
			getConversionTotal(),
			<FormatNumber value={totalSends} />,
			renderStat(totalDeliveries, totalSends),
			renderStat(totalOpens, totalDeliveries),
			renderStat(totalClicks, totalDeliveries),
			renderStat(totalBounces, totalSends),
			renderStat(totalSpam, totalDeliveries),
			renderStat(totalUnsubscribes, totalDeliveries)
		];
	}

	function getConversionTotal() {
		const total = currentNewsletters.reduce(
			(acc, curr) => {
				acc.conversions += curr.conversionSummary.conversions;
				acc.totalRevenue += curr.conversionSummary.totalRevenue;
				return acc;
			},
			{ conversions: 0, totalRevenue: 0 }
		);
		return renderConversion(total.conversions, total.totalRevenue);
	}

	function renderConversion(conversions: number, totalRevenue: number) {
		return (
			<Tooltip content={<NumberedLabel value={conversions} singular="order" />}>
				<ConversionFormatMoney amount={totalRevenue} code={getCurrencyCode(account?.currency)} />
			</Tooltip>
		);
	}

	return (
		<Card
			title={
				<Stack alignment="center">
					<Stack.Item fill>
						<Heading>Recent newsletters</Heading>
					</Stack.Item>
					<Button onClick={() => setShowExportNewsletterStatsModal(true)} loading={isExportingNewsletterSendStats} variant="plain">
						Export newsletter activity
					</Button>
					<Button onClick={refetch} loading={isLoading} variant="plain">
						Refresh
					</Button>
					<Pagination
						hasPrevious={currentPage > 0}
						onPrevious={() => setCurrentPage(currentPage - 1)}
						hasNext={hasNextPage || currentPage !== recentNewsletters.length - 1}
						onNext={() => {
							const nextPage = currentPage + 1;
							setCurrentPage(nextPage);
							if (nextPage === recentNewsletters.length) {
								fetchNextPage();
							}
						}}
					/>
				</Stack>
			}
			sectioned>
			<SlimPolarisTableButtons>
				<DataTable
					verticalAlign="middle"
					columnContentTypes={[
						'text',
						'text',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'text',
						'text',
						'text'
					]}
					headings={[
						'Sent at',
						'Name',
						'Conversions',
						'Sends',
						'Deliveries',
						'Opens',
						'Clicks',
						'Bounces',
						'Complaints',
						'Unsubscribes',
						'Recipients',
						'Discount',
						'Actions'
					]}
					rows={currentNewsletters.map(newsletter => {
						const {
							id,
							name,
							sends,
							deliveries,
							opens,
							clicks,
							bounces,
							spamComplaints,
							unsubscribes,
							sentAt,
							expectedCompletionAt,
							discountCodeType,
							discountCode,
							targetRecipients,
							conversionSummary
						} = newsletter;
						return [
							<Stack spacing="extraTight">
								{sentAt && (
									<Tooltip content={format(sentAt, 'eeee, MMMM do yyyy, h:mm:ss a')}>
										<NoWrap>{format(sentAt, 'yyyy-MM-dd')}</NoWrap>
									</Tooltip>
								)}
								{expectedCompletionAt &&
									(new Date().getTime() > expectedCompletionAt.getTime() ? (
										<Tooltip content={`Completed: ${format(expectedCompletionAt, 'eeee, MMMM do yyyy, h:mm:ss a')}`}>
											<Badge tone="success">Warmed</Badge>
										</Tooltip>
									) : (
										<Stack alignment="center" spacing="extraTight" vertical>
											<Tooltip content={`Expected completion: ${format(expectedCompletionAt, 'eeee, MMMM do yyyy, h:mm:ss a')}`}>
												<Badge tone="warning">Warming</Badge>
											</Tooltip>
											{isLeadOrAdmin(user) && (
												<Button
													onClick={() => onShowCancelNewsletterModal(newsletter)}
													loading={isCancellingNewsletter && selectedNewsletter?.id === id}
													variant="plain">
													Cancel
												</Button>
											)}
										</Stack>
									))}
							</Stack>,
							<Tooltip content={name}>
								{targetRecipients === 'INHERITED_NON_OPENS' ? (
									<TextStyle variation="strong">{truncateText(name)}</TextStyle>
								) : (
									truncateText(name)
								)}
							</Tooltip>,
							renderConversion(conversionSummary.conversions, conversionSummary.totalRevenue),
							<FormatNumber value={sends} />,
							renderStat(deliveries, sends),
							renderStat(opens, deliveries),
							renderStat(clicks, deliveries),
							renderStat(bounces, sends),
							renderStat(spamComplaints, deliveries),
							renderStat(unsubscribes, deliveries),
							<TargetRecipientsDescription accountId={accountId} newsletter={newsletter} />,
							discountCode ? (
								<Tooltip content={discountCode}>
									<p>{discountCodeType}</p>
								</Tooltip>
							) : (
								<p>{discountCodeType}</p>
							),
							<Stack alignment="center" wrap={false}>
								<NewsletterPreviewAction accountId={accountId} newsletterId={newsletter.id} />
								<Tooltip content="Export activity">
									<Button
										icon={ExportIcon}
										onClick={() =>
											setActivityToExport({
												eventId: id,
												isCampaign: true,
												name: name.substring(0, 15)
											})
										}
										loading={isExportingEmailActivity && activityToExport?.eventId === id}
										variant="plain"
									/>
								</Tooltip>
								{account && (
									<Tooltip content="SparkPost analytics">
										<Button url={getSparkPostActivityUrl(account, newsletter)} icon={ChartVerticalIcon} variant="plain" />
									</Tooltip>
								)}
							</Stack>
						];
					})}
					totals={getTotals()}
					hideScrollIndicator
					showTotalsInFooter
					fixedFirstColumns={2}
				/>
			</SlimPolarisTableButtons>
			<Modal
				title="Export newsletter activity"
				primaryAction={{
					content: 'Export',
					onAction: onExportNewsletterSendStats,
					loading: isExportingNewsletterSendStats
				}}
				secondaryActions={[{ content: 'Cancel', onAction: onHideExportNewsletterStatsModal }]}
				footer={<TextStyle variation="subdued">Exporting {newsletterStatExportLimit} newsletters</TextStyle>}
				onClose={onHideExportNewsletterStatsModal}
				open={showExportNewsletterStatsModal}>
				<Modal.Section>
					<Form onSubmit={onExportNewsletterSendStats}>
						<FormLayout>
							<RangeSlider
								output
								value={newsletterStatExportLimit}
								onChange={value => setNewsletterStatExportLimit(Number(value))}
								label="Newsletters to export"
								helpText="Export will begin with the most recent newsletter sends."
								min={5}
								max={5000}
								step={5}
								disabled={isExportingNewsletterSendStats}
							/>
						</FormLayout>
					</Form>
				</Modal.Section>
			</Modal>
			<Modal
				title={`Cancel newsletter - ${selectedNewsletter?.name}`}
				primaryAction={{
					content: 'Perform',
					onAction: onCancelNewsletter,
					loading: isCancellingNewsletter
				}}
				secondaryActions={[{ content: 'Cancel', onAction: onHideCancelNewsletterModal }]}
				footer={cancelNewsletterError ? <TextStyle variation="negative">Something went wrong.</TextStyle> : <></>}
				onClose={onHideCancelNewsletterModal}
				open={showCancelNewsletterModal}
			/>
		</Card>
	);
}

const NoWrap = styled.p`
	white-space: nowrap;
`;
