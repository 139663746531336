import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { INTERCOM_COMPANY } from './query-keys';
import type { Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

type IntercomCompany = {
	id: string;
};

export function useIntercomCompany(shop: string) {
	const { get } = useAuthenticatedFetch() as Get<IntercomCompany>;

	const { data, isFetching: isLoading } = useQuery({
		queryKey: queryKey(INTERCOM_COMPANY, shop),
		queryFn: () => get(`/intercom/company/${shop}`),
		enabled: Boolean(shop)
	});

	return {
		intercomCompanyId: data?.id,
		isLoading
	};
}
