import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import {
	SEND_MONITORING_DELAYED_NEWSLETTERS,
	SEND_MONITORING_MISSED_SCHEDULE,
	SEND_MONITORING_NEWSLETTER_PROGRESS,
	SEND_MONITORING_SENDING_USAGE
} from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

const REFETCH_OPTIONS = {
	refetchInterval: 10_000,
	refetchIntervalInBackground: false,
	refetchOnWindowFocus: true,
	refetchOnReconnect: true
};

const SENDING_USAGE_REFETCH_INTERVAL = 60_000;

export type UsageLimit = {
	limit: number;
	used: number;
	start: Date;
	end: Date;
};

export type SendingUsage = {
	timestamp: Date;
	day: UsageLimit;
	month: UsageLimit;
};

export function useSendingUsage() {
	const { get } = useAuthenticatedFetch() as Get<SendingUsage>;
	const { data, refetch, isLoading } = useQuery({
		queryKey: queryKey(SEND_MONITORING_SENDING_USAGE),
		queryFn: () => get('/marketing-platform-send-monitoring/sending-usage'),
		...REFETCH_OPTIONS,
		refetchInterval: SENDING_USAGE_REFETCH_INTERVAL,
		select: useDateTransformerFactory('timestamp, start, end')
	});

	return {
		sendingUsage: data,
		isLoadingSendingUsage: isLoading,
		refetchSendingUsage: refetch
	};
}

export type CampaignProgressSummary = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
	billedSubscribers: number;
	totalDeliveries: number;
};

export function useNewsletterProgress() {
	const { get } = useAuthenticatedFetch() as Get<CampaignProgressSummary[]>;
	const { data, refetch, isLoading } = useQuery({
		queryKey: queryKey(SEND_MONITORING_NEWSLETTER_PROGRESS),
		queryFn: () => get('/marketing-platform-send-monitoring/newsletter-progress'),
		...REFETCH_OPTIONS,
		select: useDateTransformerFactory('scheduledAt')
	});

	return {
		newsletterProgressSummaries: data ?? [],
		isLoadingNewsletterProgress: isLoading,
		refetchNewsletterProgress: refetch
	};
}

export type DelayedCampaignSummary = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
	sendCount: number;
	deliveryCount: number;
	bounceCount: number;
};

export function useDelayedNewsletters() {
	const { get } = useAuthenticatedFetch() as Get<DelayedCampaignSummary[]>;
	const { data, refetch, isLoading } = useQuery({
		queryKey: queryKey(SEND_MONITORING_DELAYED_NEWSLETTERS),
		queryFn: () => get('/marketing-platform-send-monitoring/delayed-newsletters'),
		...REFETCH_OPTIONS,
		select: useDateTransformerFactory('scheduledAt')
	});

	return {
		delayedNewsletters: data ?? [],
		isLoadingDelayedNewsletters: isLoading,
		refetchDelayedNewsletters: refetch
	};
}

export type MissedScheduleCampaign = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
};

export function useMissedScheduleNewsletters() {
	const { get } = useAuthenticatedFetch() as Get<MissedScheduleCampaign[]>;
	const { data, refetch, isLoading } = useQuery({
		queryKey: queryKey(SEND_MONITORING_MISSED_SCHEDULE),
		queryFn: () => get('/marketing-platform-send-monitoring/missed-schedule'),
		...REFETCH_OPTIONS,
		select: useDateTransformerFactory('scheduledAt')
	});

	return {
		missedScheduleNewsletters: data ?? [],
		isLoadingMissedScheduleNewsletters: isLoading,
		refetchMissedScheduleNewsletters: refetch
	};
}
