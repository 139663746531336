import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { BULK_ACCOUNTS, BULK_ACCOUNT_BILLING_DATE, BULK_ACCOUNT_JOBS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useBulkAccount(id) {
	const { get } = useAuthenticatedFetch();
	const accountPath = `/bulk-accounts/${id}`;
	const { data: account } = useQuery({ queryKey: queryKey(BULK_ACCOUNTS, id), queryFn: () => get(accountPath) });

	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery({ queryKey: queryKey(BULK_ACCOUNT_JOBS, id), queryFn: () => get(`${accountPath}/jobs`) });

	const { data: billingDate } = useQuery({
		queryKey: queryKey(BULK_ACCOUNT_BILLING_DATE, id),
		queryFn: () => get(`${accountPath}/billing-date`)
	});

	return { account, jobs, onLoadJobs, isJobsLoading, billingDate };
}
