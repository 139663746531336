import { forLocalDateInRange, LocalDate, Periods, queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { POPUP_ENGAGEMENTS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function usePopupEngagements(accountId, from, to, period = 'day', popups = []) {
	const { get } = useAuthenticatedFetch();
	const { data, isFetching } = useQuery({
		queryKey: queryKey(POPUP_ENGAGEMENTS, accountId, popups.length === 0 ? 'all' : 'popups'),
		queryFn: () => get(popups.length === 0 ? `/popup-accounts/${accountId}/engagement` : `/popup-accounts/${accountId}/engagement/popups`)
	});

	const keyFunction = Periods[period].fn;

	const engagements = useMemo(() => {
		const dates = {};
		if (from && to) {
			forLocalDateInRange({ from: LocalDate.from(from), to: LocalDate.from(to) }, date => {
				dates[keyFunction(date)] = {
					impressionCount: 0,
					conversionCount: 0,
					emailSubscriptionCount: 0,
					phoneSubscriptionCount: 0,
					linkRedirectCount: 0
				};
			});
		}

		return (data || []).reduce((acc, curr) => {
			if (popups.length > 0 && !popups.includes(curr.popupId)) {
				return acc;
			}
			const localDate = LocalDate.from(curr.engagementDate);
			const key = keyFunction(localDate);
			if (!acc[key]) {
				acc[key] = {
					impressionCount: 0,
					conversionCount: 0,
					emailSubscriptionCount: 0,
					phoneSubscriptionCount: 0,
					linkRedirectCount: 0
				};
			}
			acc[key] = {
				impressionCount: acc[key].impressionCount + curr.impressionCount,
				conversionCount: acc[key].conversionCount + curr.conversionCount,
				emailSubscriptionCount: acc[key].emailSubscriptionCount + curr.emailSubscriptionCount,
				phoneSubscriptionCount: acc[key].phoneSubscriptionCount + curr.phoneSubscriptionCount,
				linkRedirectCount: acc[key].linkRedirectCount + curr.linkRedirectCount
			};
			return acc;
		}, dates);
	}, [data, keyFunction, from, to, popups]);

	const groups = useMemo(() => {
		if (!from || !to) {
			return [];
		}
		const groupRange = new Set();
		forLocalDateInRange({ from: LocalDate.from(from), to: LocalDate.from(to) }, date => groupRange.add(keyFunction(date)));
		return Array.from(groupRange).sort();
	}, [from, to, keyFunction]);

	return { engagements, groups, isLoading: !data || isFetching };
}
