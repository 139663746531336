import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ACCOUNTS_AUTOMATIONS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
/**
 * @typedef {{
 *   id: number;
 *   name: string;
 *   type: string;
 *   enabledChangedAt: number;
 *   enabledMessages: number;
 *   sends: number;
 *   sendSummary: string;
 *   exclusionTags: string[];
 *   triggerDelay: number;
 *   sendFirstMessageTransactionally: boolean;
 * 	 messages: object[];
 *   nodes: object[];
 * }} Automation
 */

export function useAutomations(id) {
	const { get } = useAuthenticatedFetch();
	const queryClient = useQueryClient();
	const automationsQueryKey = queryKey(MARKETING_PLATFORM_ACCOUNTS_AUTOMATIONS, id);

	const setAutomationData = (automation) => {
		const index = automations.findIndex(({ id }) => id === automation.id);
		queryClient.setQueryData(automationsQueryKey, [...automations.slice(0, index), automation, ...automations.slice(index + 1)]);
	};

	const {
		data: automations,
		refetch: onLoad,
		isFetching: isLoading
	} = useQuery({ queryKey: automationsQueryKey, queryFn: () => get(`/marketing-platform-accounts/${id}/automations`) });
	const {
		data: automation,
		mutate: onLoadMessages,
		isPending: isLoadingMessages
	} = useMutation({
		mutationFn: automationId => get(`/marketing-platform-accounts/${id}/automations/${automationId}`),
		onSuccess: setAutomationData
	});

	return {
		/** @type {Automation[]} */ automations,
		onLoad,
		isLoading,
		/** @type {Automation} */ automation,
		onLoadMessages,
		isLoadingMessages
	};
}
