import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BULK_ACCOUNTS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useBulkAccountActions(id) {
	const { post, put } = useAuthenticatedFetch();
	const { post: cancel } = useAuthenticatedFetch();

	const queryClient = useQueryClient();

	function getActionPath(path) {
		return `/bulk-accounts/${id}${path}`;
	}

	const { mutate: setDiscountSetsAllowed, isPending: isSettingDiscountSetsAllowed } = useMutation({
		mutationFn: discountSetsAllowed =>
			put(getActionPath('/set-discount-sets-allowed'), {
				discountSetsAllowed
			}),
		onSuccess: data => queryClient.setQueryData(queryKey(BULK_ACCOUNTS, id), data)
	});

	const { mutate: setBasePrice, isPending: isSettingBasePrice } = useMutation({
		mutationFn: basePrice =>
			put(getActionPath('/set-base-price'), {
				basePrice
			}),
		onSuccess: data => queryClient.setQueryData(queryKey(BULK_ACCOUNTS, id), data)
	});

	const { mutate: cancelCharge, isPending: isCancellingCharge } = useMutation({
		mutationFn: () => cancel(getActionPath('/cancel-charge')),
		onSuccess: data => queryClient.setQueryData(queryKey(BULK_ACCOUNTS, id), data)
	});

	const { mutate: setContact, isPending: isSettingContact } = useMutation({
		mutationFn: ({ contactName, contactEmail }) => post(getActionPath('/set-contact'), { contactName, contactEmail }),
		onSuccess: data => queryClient.setQueryData(queryKey(BULK_ACCOUNTS, id), data)
	});

	return {
		setDiscountSetsAllowed,
		isSettingDiscountSetsAllowed,
		setBasePrice,
		isSettingBasePrice,
		cancelCharge,
		isCancellingCharge,
		setContact,
		isSettingContact
	};
}
