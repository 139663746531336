import { useMutation } from '@tanstack/react-query';
import type { Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

type DelegateAccessToken = {
	accessToken: string;
};

export function useDelegateAccessToken(urlPrefix?: string, accountId?: number) {
	const { post } = useAuthenticatedFetch() as Post<void, DelegateAccessToken>;
	const { mutate, data, isPending: isLoading } = useMutation({ mutationFn: () => post(`${urlPrefix}/delegate-access-token/${accountId}`) });
	return {
		requestDelegateAccessToken: mutate,
		accessToken: data?.accessToken,
		isLoading
	};
}
