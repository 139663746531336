import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ANNOUNCEMENTS } from '../query-keys';
import type { Delete, Get, Post, Put } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type AnnouncementStatus = 'DEFAULT' | 'SUCCESS' | 'INFO' | 'WARNING' | 'CRITICAL';

export type Announcement = {
	id: number;
	enabled: boolean;
	status: AnnouncementStatus;
	title: string;
	description: string;
};

const BASE_PATH = '/marketing-platform-announcements';

export function useAnnouncements() {
	const {
		get,
		post,
		put,
		delete: del
	} = useAuthenticatedFetch() as Get<Announcement[]> &
		Post<Partial<Announcement>, Announcement> &
		Put<Partial<Announcement>, Announcement> &
		Delete<number>;
	const queryClient = useQueryClient();

	const { data, isFetching: isLoading } = useQuery({ queryKey: queryKey(MARKETING_PLATFORM_ANNOUNCEMENTS), queryFn: () => get(BASE_PATH) });

	const { mutate: createAnnouncement, isPending: isCreating } = useMutation({
		mutationFn: (announcement: Partial<Announcement>) => post(BASE_PATH, announcement),

		onSuccess: () => queryClient.removeQueries({ queryKey: queryKey(MARKETING_PLATFORM_ANNOUNCEMENTS) })
	});
	const { mutate: setAnnouncementEnabled, isPending: isSettingEnablement } = useMutation({
		mutationFn: ({ id, enabled }: { id: number; enabled: boolean }) => post(`${BASE_PATH}/${id}/set-enabled`, { enabled }),
		onSuccess: () => queryClient.removeQueries({ queryKey: queryKey(MARKETING_PLATFORM_ANNOUNCEMENTS) })
	});

	const { mutate: updateAnnouncement, isPending: isUpdating } = useMutation({
		mutationFn: (announcement: Partial<Announcement>) => put(`${BASE_PATH}/${announcement.id}`, announcement),
		onSuccess: () => queryClient.removeQueries({ queryKey: queryKey(MARKETING_PLATFORM_ANNOUNCEMENTS) })
	});

	const { mutate: deleteAnnouncement, isPending: isDeleting } = useMutation({
		mutationFn: (id: number) => del(`${BASE_PATH}/${id}`),
		onSuccess: () => queryClient.removeQueries({ queryKey: queryKey(MARKETING_PLATFORM_ANNOUNCEMENTS) })
	});

	return {
		announcements: data ?? [],
		isLoading,
		createAnnouncement,
		isCreating,
		setAnnouncementEnabled,
		isSettingEnablement,
		updateAnnouncement,
		isUpdating,
		deleteAnnouncement,
		isDeleting
	};
}
