import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { POPUP_BILLING_USAGE_SUMMARY } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function usePopupBillingUsageSummary(accountId) {
	const { get } = useAuthenticatedFetch();
	const { data, isFetching: isLoading } = useQuery({
		queryKey: queryKey(POPUP_BILLING_USAGE_SUMMARY, accountId),
		queryFn: () => get(`/popup-accounts/${accountId}/usage-summary`)
	});

	return { summary: data, isLoading };
}
