import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import type { BaseBillableAccount } from '../../lib/shared-types';
import { BANNER_ACCOUNTS, BANNER_ACCOUNT_JOBS, BANNER_ACCOUNT_SCRIPT_TAG } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useBannerAccount(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<BaseBillableAccount>;
	const accountPath = `/banner-accounts/${id}`;
	const { data: account } = useQuery({
		queryKey: queryKey(BANNER_ACCOUNTS, id),
		queryFn: () => get(accountPath),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery({
		queryKey: queryKey(BANNER_ACCOUNT_JOBS, id),
		queryFn: () => get(`${accountPath}/jobs`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	const { data: scriptTag } = useQuery({
		queryKey: queryKey(BANNER_ACCOUNT_SCRIPT_TAG),
		queryFn: () => get(`${accountPath}/script-tag`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	return { account, jobs, onLoadJobs, isJobsLoading, scriptTag };
}
