import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import type { BaseAccount } from '../../lib/shared-types';
import { POPUP_ACCOUNT, POPUP_ACCOUNT_BILLING_DATE, POPUP_ACCOUNT_JOBS, POPUP_ACCOUNT_POPUPS, POPUP_ACCOUNT_VERIFY } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type PostscriptPropertyNameMapping = {
	firstName?: string;
	lastName?: string;
	birthdate?: string;
};

export type PopupAccount = BaseAccount & {
	brandingHideable: boolean;
	paidPlan: boolean;
	cappedAmount: number;
	unitAmount: number;
	freeIncludedUnits: number;
	includedUnits: number;
	includedUnitsForPlan: number;
	postscriptConnected: boolean;
	postscriptDefaultKeywordId?: string;
	postscriptPropertyNameMapping: PostscriptPropertyNameMapping;
};

export function usePopupAccount(id: string | number = '') {
	const { get } = useAuthenticatedFetch() as Get<PopupAccount>;
	const accountPath = `/popup-accounts/${id}`;
	const { data: account } = useQuery({
		queryKey: queryKey(POPUP_ACCOUNT, id),
		queryFn: () => get(accountPath),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery({
		queryKey: queryKey(POPUP_ACCOUNT_JOBS, id),
		queryFn: () => get(`${accountPath}/jobs`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	const {
		data: popups,
		refetch: onLoadPopups,
		isFetching: isPopupsLoading
	} = useQuery({
		queryKey: queryKey(POPUP_ACCOUNT_POPUPS, id),
		queryFn: () => get(`${accountPath}/popups`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt', 'activeStartDate', 'activeEndDate')
	});

	const {
		data: installVerified,
		refetch: onVerifyInstall,
		error: verifyInstallError
	} = useQuery({
		queryKey: queryKey(POPUP_ACCOUNT_VERIFY, id),
		queryFn: () => get(`${accountPath}/verify-installation`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	const { data: billingDate } = useQuery({
		queryKey: queryKey(POPUP_ACCOUNT_BILLING_DATE, id),
		queryFn: () => get(`${accountPath}/billing-date`),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt')
	});

	return {
		account,
		jobs,
		onLoadJobs,
		isJobsLoading,
		popups,
		onLoadPopups,
		isPopupsLoading,
		installVerified,
		onVerifyInstall,
		verifyInstallError,
		billingDate
	};
}
