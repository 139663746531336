import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { USER } from './query-keys';
import type { Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AdminRole = 'ADMIN' | 'SUPPORT' | 'SUPPORT_LEAD' | 'EMPLOYEE' | 'DELIVERABILITY';

export type AdminUser = {
	firstName: string;
	lastName: string;
	email: string;
	role: AdminRole;
	password: string;
};

export type AdminRoleFn = (user: AdminUser | undefined) => boolean;

export function useUser() {
	const { get } = useAuthenticatedFetch() as Get<AdminUser>;
	const { data } = useQuery({ queryKey: queryKey(USER), queryFn: () => get('/auth/whoami') });
	return data;
}
