import { queryKey } from '@segunosoftware/equinox';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export function useRecentAccounts(key, path, filters, search = '', page = 1, limit = 10) {
	const { get } = useAuthenticatedFetch();

	const query = useMemo(() => queryString.stringify({ ...filters, limit, search }), [filters, limit, search]);

	const {
		data,
		refetch: load,
		isFetching: isLoading
	} = useQuery({
		queryKey: queryKey(key, query, page),
		queryFn: () => get(`${path}?${query}&page=${encodeURIComponent(page)}`),
		enabled: false,
		placeholderData: keepPreviousData
	});

	useEffect(() => {
		load();
	}, [query, page, load]);

	return { accounts: data?.accounts ?? [], count: data?.count ?? 0, load, isLoading };
}
