import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { POPUP_ACCOUNT } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function usePopupAccountActions(id) {
	const { post } = useAuthenticatedFetch();
	const { post: suspend } = useAuthenticatedFetch();

	const queryClient = useQueryClient();

	function getActionPath(path) {
		return `/popup-accounts/${id}${path}`;
	}

	const { mutate: updateTrial, isPending: isUpdatingTrial } = useMutation({
		mutationFn: newTrialDate => post(getActionPath('/update-trial'), newTrialDate),
		onSuccess: data => queryClient.setQueryData(queryKey(POPUP_ACCOUNT, id), data)
	});

	const { mutate: setSuspended, isPending: isSettingSuspended } = useMutation({
		mutationFn: suspended =>
			suspend(getActionPath('/set-suspended'), {
				suspended
			}),
		onSuccess: data => queryClient.setQueryData(queryKey(POPUP_ACCOUNT, id), data)
	});

	const { mutate: cancelCharge, isPending: isCancellingCharge } = useMutation({
		mutationFn: () => post(getActionPath('/cancel-charge')),
		onSuccess: data => queryClient.setQueryData(queryKey(POPUP_ACCOUNT, id), data)
	});

	return {
		updateTrial,
		isUpdatingTrial,
		setSuspended,
		isSettingSuspended,
		cancelCharge,
		isCancellingCharge
	};
}
