import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useMarketingPlatformAccountResourceFeedback(account) {
	const { get } = useAuthenticatedFetch();
	const { data: resourceFeedback, isFetching: isLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK, account.id),
		queryFn: () => get(`/marketing-platform-accounts/${account.id}/resource-feedback`),
		enabled: account.supportsResourceFeedback
	});
	return { resourceFeedback, isLoading };
}
